<template>
  <div>
    <!-- <a-col :span="6">
        <div class="end">
          <a-button class="searchLoading-button" type="primary" @click="onAdd">
            新增
          </a-button>
        </div>
      </a-col> -->
    <a-table v-if="listData.length > 0" size="small" :columns="columns" :row-key="record => record.customerId" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
    </a-table>
    <a-empty v-else description="暂无跟进记录" />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { clueCustomerIdPage, getClueInfo, edit } from '@/api/crmManagement/clue'
import { getShippingUse } from '@/api/global'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'

export default {
  props: {
    customerId: String
  },
  setup (props, context) {
    const store = useStore()
    const state = reactive({
      editForm: {
        transportPurpose: '',
        clueType: { value: 0 },
        isEffective: {},
        returnPlan: {}
      },
      cid: props.customerId,
      invalid: '',
      listData: [],
      shippingList: [],
      labelList: [],
      loading: false,
      show: false,
      prompt: false,
      startAddressStatus: false,
      endAddressStatus: false,
      columns: [
        {
          title: '线索类型',
          dataIndex: 'clueType.label'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '起点',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          dataIndex: 'endAddress'

        },
        // {
        //   title: '线索电话',
        //   dataIndex: 'phone'
        // },
        // {
        //   title: '线索微信',
        //   dataIndex: 'wechat'
        // },
        {
          title: '报价',
          dataIndex: 'totalPrice'
        },
        {
          title: '托运用途',
          dataIndex: 'transportPurpose'
        },
        {
          title: 'IP地址',
          dataIndex: 'ipAddress'
        }
      ],
      phoneDisabled: true,
      pagination: {
        customerId: props.customerId,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: 1,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const handleChange = (e, v) => {
      if (e[0] === '') e.splice(0, 1)
      if (e.length > 1) {
        message.error('不能选择多个用途')
        e.splice(1, 1)
      } else state.editForm.transportPurpose = e[0]
    }
    const setStartAddress = (e, v) => {
      state.editForm.startAddress = ''
      v.forEach(item => {
        state.editForm.startAddress += item.label
      })
    }
    const setEndAddress = (e, v) => {
      state.editForm.endAddress = ''
      v.forEach(item => {
        state.editForm.endAddress += item.label
      })
    }
    const loadData = () => {
      state.loading = true
      clueCustomerIdPage(state.cid, {
        customerId: state.cid,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
          // console.log(state.data)
        }
      }).catch(err => {
        console.log('分页错误', err)
      }).finally(() => {
        state.loading = false
      })
    }
    onMounted(() => {
      if (props.customerId) state.cid = props.customerId
      getShippingUse({ label: '', value: 5 })
        .then(res => {
          if (res.code === 10000) state.shippingList = res.data
        }).catch(err => { console.log(err) })
      // 获取无效线索list
      setTimeout(() => { state.labelList = store.state.enumAll.InvalidClue }, 200)
      loadData()
    })
    // 无效标签change
    const invalidChange = (e, v) => {
      if (v !== undefined) {
        state.editForm.invalidLabelId = v.value
        state.editForm.invalidLabelName = v.label
      } else {
        delete state.editForm.invalidLabelId
        delete state.editForm.invalidLabelName
      }
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onEdit = (e) => {
      getClueInfo({ clueId: e.id }).then(res => {
        if (res.code === 10000) {
          if (res.data.clueType === null) res.data.clueType = {}
          state.editForm = res.data
          if (state.editForm.startAddress !== '' && state.editForm.startAddress !== null) state.startAddressStatus = true
          else state.startAddressStatus = false
          if (state.editForm.endAddress !== '' && state.editForm.endAddress !== null) state.endAddressStatus = true
          else state.endAddressStatus = false
          if (res.data.phone === null || res.data.phone === '') state.phoneDisabled = false
          state.editForm.channe = [res.data.channelId, res.data.channelSubId]
        }
      })
      state.show = true
    }
    const onOK = () => {
      edit(state.editForm).then(res => {
        if (res.code === 10000) {
          state.show = false
          message.success(res.msg)
          loadData()
        }
      })
    }

    const setChannel = (value, selectedOptions) => {
      state.editForm.channelId = value[0]
      if (value.length > 1) {
        state.editForm.channelSubId = value[1]
      }
    }

    const clueChange = (e, v) => {
      if (e === 0) state.prompt = true
      else state.prompt = false
    }
    return {
      ...toRefs(state),
      loadData,
      handleChange,
      setStartAddress,
      setEndAddress,
      invalidChange,
      handleTableChange,
      clueChange,
      onEdit,
      setChannel,
      onOK
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../tool/index.less';
@import './index.less';
</style>
