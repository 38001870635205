import { postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/market/crm/temporary/page', params)
}
export function call (params) {
  return postBodyRequest('/market/crm/public/receive/call', params)
}
export function receive (customerId) {
  return postBodyRequest(`/market/crm/temporary/receive/${customerId}`)
}
export function add (params) {
  return postBodyRequest('/market/crm/public/add', params)
}
export function addPublic (params) {
  return postBodyRequest('/market/crm/public/add/public', params)
}

export function allocate (params) {
  return postBodyRequest('/market/crm/public/compulsoryAllocate', params)
}
